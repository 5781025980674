<template>
  <div>
    <!-- Start Header -->
    <div class="ContentHeader">
      <div class="HeaderAll">
        <!-- Slot Breadcrumb -->
        <slot name="titlebreadcrumb"></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <!-- Slot Benefit Header-->
      <slot name="benefitheader"></slot>
    </div>
    <!-- End Header -->

    <!-- slot menu -->
    <slot name="menuall"></slot>

    <div
      class="allcard"
      style="border-top-left-radius: 0px; height: calc(100vh - (282px + 3.7vh))"
    >
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <!-- start content here -->
        <!-- advanced search -->
        <div style="display: flex; justify-content: space-between">
          <div class="menubenefit">
            <ul v-if="tab.length > 0">
              <li v-for="(item, i) in tab" :key="i">
                <!-- :to="{ name: 'ListBenefit', query: { plan: item.sheet } }"
                :class="$router.query.plan == item.sheet ? 'activebenefit' : ''" -->
                <router-link
                  :to="{ name: 'ListBenefit', query: { plan: item.sheet } }"
                  :class="
                    $route.query.plan == item.sheet ? 'activebenefit' : ''
                  "
                  >{{ item.sheet }}</router-link
                >
              </li>
            </ul>
          </div>
          <div class="ContentHeaderSearch contentsearchpt">
            <a-input-search
              placeholder="Search Here"
              style="width: 327px"
              @search="onSearch"
              allowClear
            />

            <!-- <a-button type="advancedsearch" @click="advanced"
            >Advanced Search <a-icon type="filter" />
          </a-button>
          <div class="advancedsearch-dropdown" v-if="advancedsearch">
            <div class="_dropdownwraper">asdsadas</div>
          </div> -->
          </div>
        </div>
        <!-- end advanced search -->
        <a-table
          :rowKey="(record) => record.rowid"
          :columns="column"
          :dataSource="FilterData"
          :loading="loading"
          :pagination="{
            pageSize: 10000,

            showTotal: (total, range) =>
              range[0] + '-' + range[1] + ' of ' + total,
          }"
          :scroll="{ x: widthtable }"
          @change="handlePageChange"
          :rowClassName="customRow"
        >
          <span
            v-for="(item, i) in rawcolumn"
            :key="i"
            :slot="`customTitle-${i}`"
          >
            <span v-if="i == 0 || i == 1 || i == 2">{{
              Capital(item[1].replace("_", " "))
            }}</span>

            <span v-if="i > 2">
              <span class="iconcolumn">
                <img
                  :src="require('@/assets/img/icon/ri.svg')"
                  v-if="item[1].split(' ')[0] === 'RI'"
                />
                <img
                  :src="require('@/assets/img/icon/rj.svg')"
                  v-if="item[1].split(' ')[0] == 'RJ'"
                />
                <img
                  :src="require('@/assets/img/icon/rg.svg')"
                  v-if="item[1].split(' ')[0] == 'RG'"
                />
                <img
                  :src="require('@/assets/img/icon/km.svg')"
                  v-if="item[1].split(' ')[0] == 'KM'"
                />

                <img
                  :src="require('@/assets/img/icon/rb.svg')"
                  v-if="item[1].split(' ')[0] == 'RB'"
                />
                {{ item[1] }}
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Dalam Ribuan</span>
                  </template>

                  <a-icon type="info-circle" />
                </a-tooltip>
              </span>
            </span>
          </span>

          <div
            v-for="(item, i) in rawcolumn"
            :key="i"
            :slot="`scopeCustom-${i}`"
            slot-scope="text, record, ii"
          >
            <div
              v-if="
                ii ==
                FilterData.map((key) => key.manfaat_plan).indexOf(
                  'Maks. Limit per tahun'
                )
              "
              style="font-weight: bolder"
            >
              {{ text }}
            </div>
            <div v-else>{{ text }}</div>
          </div>
          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <!-- end content here -->
        <!-- pagination -->
        <!-- <div class="paginationcustom" style="margin-top: 0px !important">
        <a-pagination
          show-size-changer
          :default-current="3"
          :total="data.length"
          @showSizeChange="onShowSizeChange"
        />
      </div> -->
        <!-- <div class="paginationcustom" style="margin-top: 0px !important">
        <a-pagination
          showSizeChanger
          @showSizeChange="onShowSizeChange"
          :pageSizeOptions="pageSizeOption"
          :pageSize.sync="pageSize"
          :total="total"
          v-model="page"
          :showTotal="
            (total, range) =>
              ` ${range[0]}-${range[1]}  of ${
                pagination.total == undefined ? '0' : pagination.total
              } `
          "
          @change="handlePageChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value !== '1000000'">
              {{ props.value }} / page
            </span>
            <span v-if="props.value === '1000000'">All</span>
          </template>
        </a-pagination>
      </div> -->
        <!-- end pagination -->
      </simplebar>
    </div>
  </div>
</template>
<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  components: {
    simplebar,
  },
  data() {
    return {
      pageSizeOption: ["5", "10", "15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 10000,
      column: [],
      rawcolumn: [],
      tab: [],
      data: [],
      advancedsearch: false,
      search: "",
      loading: false,
      heighttable: 0,
      widthtable: 0,
      breadcrumb: [
        {
          name: "Daftar Group",
          nameroute: "ListDaftarGroup",
          param: {},
        },
        {
          name: "Benefit",
          nameroute: "",
          param: {},
        },
      ],
    };
  },
  created() {
    this.tabs();
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetch({
      page: this.page,
      pageSizes: this.pageSize,
      id: this.$route.params.id,
      plan: this.$route.query.plan,
    });
  },

  computed: {
    FilterData() {
      let a = this.data
        //tes
        .filter((key, i) => i != 0)
        .filter(
          (o, i) =>
            (o["maksimal_limit"] || "")
              .toUpperCase()
              .match(this.search.toUpperCase()) ||
            (o.manfaat_plan || "")
              .toUpperCase()
              .match(this.search.toUpperCase()) ||
            (o.a || "").toUpperCase().match(this.search.toUpperCase()) ||
            (o.b || "").toUpperCase().match(this.search.toUpperCase())
        );
      //console.log(this.column.map((key) => key.dataIndex));
      return a;
    },
  },
  watch: {
    "$route.query.plan": {
      handler: function (search) {
        this.fetch({
          page: this.page,
          pageSizes: this.pageSize,
          id: this.$route.params.id,
          plan: this.$route.query.plan,
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    customRow(record, index) {
      //console.log(record);

      if (record.manfaat_plan == "Maks. Limit per tahun") {
        return "hightlightrow";
      }
    },
    handleResize() {
      this.heighttable = screen.height - 785;
      this.widthtable = screen.width - 2006;
    },
    tabs() {
      this.$store
        .dispatch("getTabBenefit", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.tab = response.data.data;
        });
    },
    Capital(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(" ");
    },

    onShowSizeChange(current, pageSize) {
      const params = this.paramssearch;
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        id: this.$route.params.id,
        plan: this.$route.query.plan,
        search: this.search,
        ...params,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      //checklist reset
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = page;

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        search: this.search,
        id: this.$route.params.id,
        plan: this.$route.query.plan,
        ...params,
      });
    },
    handleTableChange(page) {
      const pager = { ...this.pagination };
      const params = this.paramssearch;
      pager.current = page;
      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        id: this.$route.params.id,
        plan: this.$route.query.plan,
        search: this.search,
        ...params,
      });
    },
    advanced() {
      this.advancedsearch = !this.advancedsearch;
    },
    onSearch(text) {
      this.search = text;

      this.FilterData;
    },
    fetch(params = {}) {
      this.loading = true;
      this.$store.dispatch("getBenefitList", { ...params }).then((response) => {
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = response.data.total - 1;
        pagination.pageSize = response.data.pageSize;
        pagination.page = response.data.page;

        this.total = pagination.total;

        this.pagination = pagination;

        this.data = response.data.data;
        //console.log(this.pagination);

        let a = Object.fromEntries(
          Object.entries(this.data[0]).filter(
            ([key, value]) => typeof value === "string"
          )
        );
        let b = Object.entries(this.data[0]);

        let d = { 0: b[1][0], 1: b[2][0], 2: b[3][0] };

        let rawcolumn = { ...d, ...a };

        this.rawcolumn = Object.entries(rawcolumn);
        console.log("kolom", rawcolumn);
        this.column = this.rawcolumn.map((key, i) => ({
          dataIndex:
            key[0] == 0
              ? key[1]
              : key[0] == 1
              ? key[1]
              : key[0] == 2
              ? key[1]
              : key[0],

          //dataIndex: key[0],
          // sorter: (a, b) => a[i] - b[i],
          sortDirections: ["descend", "ascend"],
          slots: { title: "customTitle-" + i },
          fixed: i == 1 || i == 0 ? "left" : false,
          width: i == 1 || i == 0 ? 350 : 250,
          scopedSlots: { customRender: "scopeCustom-" + i },
        }));

        // console.log(this.column);
      });
    },
  },
};
</script>
